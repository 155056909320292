import { ReactComponent as Hundred } from "../img/100.svg";
import { ReactComponent as TwoHundred } from "../img/250.svg";
import { ReactComponent as EightHundred } from "../img/800.svg";
import { ReactComponent as Liter } from "../img/1500.svg";
import { ReactComponent as Threes } from "../img/threes.svg";
import { ReactComponent as Blueberry } from "../img/blueberry.svg";
import { ReactComponent as Garden } from "../img/garden.svg";
import { ReactComponent as Watercan } from "../img/watercan.svg";

export const catalogDataUniversal = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "77.74"],
        ["Mn (ЭДТА)", "60.46"],
        ["Zn (ЭДТА)", "20.19"],
        ["Cu (ЭДТА)", "14.7"],
        ["Mo", "5.75"],
        ["B", "28.6"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: ["N - 23.04", "P205 - 23.04", "K20 - 34.08", "MgO - 2.4"],
    },
    {
      type: "text",
      title: "Доп. информация:",
      data: ["N-NO3- 8.54 г/л", "N-NH4 - 2.98 г/л", "N-NH2 - 11.52 г/л"],
    },
  ];

  export const catalogDataHvoy = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "71.70"],
        ["Mn (ЭДТА)", "63.12"],
        ["Zn (ЭДТА)", "20.2"],
        ["Cu (ЭДТА)", "16.3"],
        ["Mo", "5.8"],
        ["B", "28"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: ["N - 7.57", "P205 - 17.3", "K20 - 51.12", "MgO - 5"],
    },
    {
      type: "text",
      title: "Доп. информация:",
      data: [
       "Азот N(общий) - 7.95 %",
        "в т.ч. N-NO3 - 7.3 %",
        "N-NH4 - 1 %",
      ],
    },
  ];
  
  
  export const catalogDataTui = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "77.69"],
        ["Mn (ЭДТА)", "61.50"],
        ["Zn (ЭДТА)", "19.16"],
        ["Cu (ЭДТА)", "15.0"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: [
        "N - 8.64",
        "P205 - 16.32",
        "K20 - 50.4",
        "MgO - 4.32",
      ],
    },
    {
      type: "text",
      title: "Доп. информация:",
      data: [
        "Азот N(общий) - 33.44 г/л",
        "в т.ч. N-NO3 - 9.32 г/л",
        "N-NH4 - 2.90 г/л",
      ],
    },
  ];

  export const catalogDataRassada = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "123.9"],
        ["Zn (ЭДТА)", "29.8"],
        ["Cu (ЭДТА)", "23.4"],
        ["Mn (ЭДТА)", "39.8"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: [
        "N - 41.2",
        "P205 - 39.7",
        "K20 - 40.5",
        "MgO - 3.99",
      ],
    },
  ];  

  export const catalogDataTomats = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "122.3"],
        ["Zn (ЭДТА)", "28.5"],
        ["Cu (ЭДТА)", "21.5"],
        ["B", "88.9"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: [
        "N - 24.4",
        "P205 - 16.1",
        "K20 - 61.58",
        "MgO - 2.7",
      ],
    },
  ];

  export const catalogDataKlubnika = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "102"],
        ["Zn (ЭДТА)", "27.3"],
        ["Cu (ЭДТА)", "17.3"],
        ["Mo", "5.4"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: [
        "N - 33.44",
        "P205 - 9.32",
        "K20 - 47.41",
        "MgO - 2.9",
      ],
    },
  ];
  

  export const catalogDataPalm = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "68.7"],
        ["Mn (ЭДТА)", "62.45"],
        ["Zn (ЭДТА)", "22.27"],
        ["Cu (ЭДТА)", "12.3"],
        ["Mo", "5.68"],
        ["B", "27.76"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: [
        "N - 26.25",
        "P205 - 17.73",
        "K20 - 18.6",
        "MgO - 2.33",
      ],
    },
    {
      type: "text",
      title: "Доп. информация:",
      data: [
        "N-NO3- 8.3 г/л",
        "N-NH4 - 10.56 г/л",
        "N-NH2 - 9.75 г/л",
      ],
    },
  ];

  export const catalogDataRoze = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "69.7"],
        ["Mn (ЭДТА)", "62.44"],
        ["Zn (ЭДТА)", "19.2"],
        ["Cu (ЭДТА)", "15"],
        ["Mo", "6.3"],
        ["B", "27.56"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: [
        "N - 25.5",
        "P205 - 8.2",
        "K20 - 35.44",
        "MgO - 3",
      ],
    },
    {
      type: "text",
      title: "Доп. информация:",
      data: [
        "Азот N(общий)- 25.76 %",
        "в т.ч. N-NO3 - 15.54 %",
        "N-NH4 - 8 %",
      ],
    },
  ];
  
  export const catalogDataOrc = [
    {
      type: "table",
      columns: ["Микроэлементы", "мг/л"],
      data: [
        ["Fe (ДТПА)", "69.6"],
        ["Mn (ЭДТА)", "61.42"],
        ["Zn (ЭДТА)", "21.29"],
        ["Cu (ЭДТА)", "13.6"],
        ["Mo", "4.56"],
        ["B", "25.3"],
      ],
    },
    {
      type: "text",
      title: "Состав(г/л):",
      data: ["N - 17.5", "P205 - 7.1", "K20 - 38.4", "MgO - 2.65"],
    },
    {
      type: "text",
      title: "Доп. информация:",
      data: ["Азот N(общий)- 17.9 %", "в т.ч. N-NO3 - 9.75 %", "N-NH4 - 8.12 %"],
    },
];
  
export const catalogDataSize = [
  { component: <Hundred />, size: "100 мл" },
  { component: <TwoHundred />, size: "250 мл" },
  { component: <EightHundred />, size: "800 мл" },
  { component: <Liter />, size: "1.5 л" },
];

export const catalogDataSizeSmall = [
  { component: <EightHundred />, size: "800 мл" },
  { component: <Liter />, size: "1.5 л" },
];

export const catalogDataInfo = [
  {
    icon: <Threes className="catalog__info_container_item-img" />,
    title: "Природный\nматериал",
    text: "Продукты изготовлены\nна основе 100% природного материала - цеолита, обогащенного элементами питания.",
  },
  {
    icon: <Blueberry className="catalog__info_container_item-img" />,
    title: "Больше\nурожая",
    text: "Благодаря уникальной формуле Top Green\nи натуральным\nкомпонентам\nроста.",
  },
  {
    icon: <Garden className="catalog__info_container_item-img" />,
    title: "Экологичен\nи безопасен",
    text: "Не содержит нитратов, пестицидов, гербицидов. Ускорителей\nроста\nи фитогормонов.\nОжог невозможен.",
  },
  {
    icon: <Watercan className="catalog__info_container_item-img" />,
    title: "Простота\nв использовании",
    text: "Удобная схема смешивания и внесения внутрь грунта для\nполучения максимального\nрезультата.",
  },
];