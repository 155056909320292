import "../styles/Modal.css";
import modalBackground from "../img/modalBackground.png";
import { ReactComponent as CloseButton } from "../img/closeButton.svg";
import { useRef, useState, useEffect } from "react";
import emailjs from "emailjs-com";

export default function Modal({ closeModal, isFooterModal }) {
  const formRef = useRef(null);
  const textareaRef = useRef(null);
  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Валидация полей формы
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Проверка каждого поля отдельно
    const isNameValid = nameRef.current?.value.trim() !== "";
    const isPhoneValid = phoneRef.current?.value.trim() !== "";
    const isEmailValid =
      emailRef.current?.value.trim() !== "" &&
      emailRegex.test(emailRef.current?.value);
    const isCompanyValid = companyRef.current?.value.trim() !== "";
    const isMessageValid = textareaRef.current?.value.trim() !== "";

    // Применение классов для выделения некорректных полей
    nameRef.current?.classList.toggle(
      "invalid",
      !isNameValid && nameRef.current.value.trim() !== ""
    );
    phoneRef.current?.classList.toggle(
      "invalid",
      !isPhoneValid && phoneRef.current.value.trim() !== ""
    );
    emailRef.current?.classList.toggle(
      "invalid",
      !isEmailValid && emailRef.current.value.trim() !== ""
    );
    companyRef.current?.classList.toggle(
      "invalid",
      !isCompanyValid && companyRef.current.value.trim() !== ""
    );
    textareaRef.current?.classList.toggle(
      "invalid",
      !isMessageValid && textareaRef.current.value.trim() !== ""
    );

    // Возвращаем общее состояние формы
    return (
      isNameValid &&
      isPhoneValid &&
      isEmailValid &&
      isCompanyValid &&
      isMessageValid
    );
  };

  // Обновление состояния активности кнопки при изменении полей
  const handleInputChange = () => {
    setIsFormValid(validateForm());
  };

  // Обработчик отправки формы
  const handleSend = async (e) => {
    e.preventDefault();
    if (loading || !isFormValid) return; // Если форма не валидна, отменяем отправку

    setLoading(true);

    try {
      // Отправка данных через emailjs
      await emailjs.sendForm(
        "service_tj10ifk", // ID вашего сервиса
        "template_eorsb7d", // ID вашего шаблона
        formRef.current, // Передаем саму форму
        "zsRpxRMcd0N1oTQ7d" // Публичный ключ пользователя
      );

      alert("Сообщение отправлено!");

      // Очистка полей после отправки
      if (formRef.current) {
        formRef.current.reset();
      }
      setIsFormValid(false); // Отключаем кнопку после очистки
      closeModal();
    } catch (error) {
      console.error("Ошибка при отправке сообщения:", error); // Вывод ошибки в консоль
      alert("Ошибка при отправке сообщения. Попробуйте позже.");
    } finally {
      setLoading(false);
    }
  };

  // Эффект для начальной валидации формы
  useEffect(() => {
    setIsFormValid(validateForm());
  }, []);

  return (
    <div
      className={`modal-overlay ${isFooterModal ? "footer-modal" : ""}`}
      onClick={closeModal}
    >
      <img
        src={modalBackground}
        alt="Background Image"
        className={`background-image ${
          isFooterModal ? "footer-background-image" : ""
        }`}
      />
      <form
        ref={formRef}
        className={`modal ${isFooterModal ? "footer-modal-content" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={closeModal} className="modal-close-button" />
        <div className="modal__container-main">
          <div className="modal__container_left">
            <input
              ref={nameRef}
              name="name"
              className="modal__container_left-input"
              placeholder="Ваше имя"
              onChange={handleInputChange}
            />
            <input
              ref={phoneRef}
              name="phone"
              className="modal__container_left-input"
              placeholder="Контактный телефон"
              onChange={handleInputChange}
            />
            <input
              ref={emailRef}
              name="email"
              className="modal__container_left-input"
              placeholder="E-mail"
              onChange={handleInputChange}
            />
            <input
              ref={companyRef}
              name="company"
              className="modal__container_left-input"
              placeholder="Компания"
              onChange={handleInputChange}
            />
          </div>
          <div className="modal__container_right">
            <textarea
              ref={textareaRef}
              name="message"
              className="modal__container_left-input modal__container_right-input"
              placeholder="Введите текст"
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
      </form>
      <button
        type="button" // Указываем кнопку вне формы
        className={`modal__button ${loading ? "modal__button--loading" : ""} ${
          !isFormValid ? "modal__button--disabled" : ""
        }`} // Добавляем класс для неактивной кнопки
        onClick={handleSend}
        disabled={loading || !isFormValid}
      >
        {loading ? "Отправка..." : "Отправить"}
      </button>
    </div>
  );
}

