import React, { useState, lazy, Suspense } from "react";
import { useEffect } from "react";
import "../styles/ContentSwitcher.css";
import universal from "../img/universal.png";
import hvoy from "../img/hvoy.png";
import palm from "../img/palm.png";
import rose from "../img/rose.png";
import orc from "../img/orc.png";
import klubnika from "../img/klubnika.png";
import tomats from "../img/tomats.png";
import rassada from "../img/rassada.png";
import tui from "../img/tui.png";
import { ReactComponent as EightHundred } from "../img/800.svg";
import { ReactComponent as Liter } from "../img/1500.svg";
import { ReactComponent as MadeInRussia } from "../img/madeInRussia.svg";
import { ReactComponent as MadeInRussiaMobile } from "../img/madeInRussiaMobile.svg";
import { ReactComponent as Separator } from "../img/separator.svg";
import { ReactComponent as WB } from "../img/WB.svg";
import { ReactComponent as Ozon } from "../img/Ozon.svg";
import { ReactComponent as VseInstr } from "../img/VseInstr.svg";
import { ReactComponent as Cancel } from "../img/Cancel.svg";
import { ReactComponent as BuyButton } from "../img/buyButton.svg";
import { ReactComponent as Arrow } from "../img/arrow.svg";
import { ReactComponent as Threes } from "../img/threes.svg";
import { ReactComponent as Blueberry } from "../img/blueberry.svg";
import { ReactComponent as Garden } from "../img/garden.svg";
import { ReactComponent as Watercan } from "../img/watercan.svg";
import RozeLeft from "../img/roze-left.png";
import RozeRight from "../img/roze-right.png";
import {
  catalogDataUniversal,
  catalogDataHvoy,
  catalogDataTui,
  catalogDataRassada,
  catalogDataTomats,
  catalogDataKlubnika,
  catalogDataPalm,
  catalogDataRoze,
  catalogDataOrc,
  catalogDataSize,
  catalogDataSizeSmall,
  catalogDataInfo,
} from "./CatalogData";

import Modal from './Modal';

const Carousel = lazy(() => import("./LazyCarousel.jsx"));

const productTemplate = (catalogDataUniversal) => (
  <div className="catalog__container-item catalog__container-item-carousel">
    {catalogDataUniversal.title && (
      <h3 className="catalog__container-item-title">
        {catalogDataUniversal.title}
      </h3>
    )}

    {catalogDataUniversal.type === "table" ? (
      <table className="border-none">
        <thead>
          <tr>
            {catalogDataUniversal.columns.map((col, index) => (
              <th key={index} className="catalog__container-item-title">
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {catalogDataUniversal.data.map(([key, value], rowIndex) => (
            <tr key={rowIndex}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      catalogDataUniversal.data.map((text, index) => (
        <p key={index} className="catalog__container-item-text">
          {text}
        </p>
      ))
    )}
  </div>
);

const productSizeTemplate = (catalogDataSize) => {
  return (
    <div className="catalog__buy_size-container">
      {catalogDataSize.component}
      <div className="catalog__buy_size-button catalog__buy_size-button-carousel">
        <p className="catalog__buy_size-button_text-top">
          {catalogDataSize.size.split(" ")[0]}
        </p>
        <p className="catalog__buy_size-button_text-bottom">
          {catalogDataSize.size.split(" ")[1]}
        </p>
      </div>
    </div>
  );
};

const productSizeSmallTemplate = (catalogDataSizeSmall) => {
  return (
    <div className="catalog__buy_size-container">
      {catalogDataSizeSmall.component}
      <div className="catalog__buy_size-button catalog__buy_size-button-carousel">
        <p className="catalog__buy_size-button_text-top">
          {catalogDataSizeSmall.size.split(" ")[0]}
        </p>
        <p className="catalog__buy_size-button_text-bottom">
          {catalogDataSizeSmall.size.split(" ")[1]}
        </p>
      </div>
    </div>
  );
};

const infoTemplate = (catalogDataInfo) => {
  return (
    <div className="catalog__info_container_item">
      {catalogDataInfo.icon}
      <h3 className="catalog__info_container_item-title">
        {catalogDataInfo.title}
      </h3>
      <p className="catalog__info_container_item-text">
        {catalogDataInfo.text}
      </p>
    </div>
  );
};

function ContentSwitcher() {
  const [activeContent, setActiveContent] = useState("catalog");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]); // Храним индексы открытых вопросов
  const [isVisible, setVisibleItems] = useState({}); // Состояние видимости для каждого элемента

  const toggleVisibility = (id) => {
    setVisibleItems((prev) => ({
      ...prev,
      [id]: !prev[id], // Переключаем видимость конкретного элемента
    }));
  };

  // Данные FAQ
  const faqData = [
    {
      question: "Что такое TOP GREEN?",
      answer: (
        <>
          <p>
            TOP GREEN – натуральные питательные добавки. Для производства
            продукта используются природные минералы – цеолиты. Он содержит в
            себе питательные вещества и микроэлементы и постепенно отдает их
            растениям в обмен на соответствующие метаболиты (продукты
            жизнедеятельности растения) - в нужное время и в необходимом объеме,
            по сути, по запросу растения. Благодаря этому передозировка
            элементов питания и ожог корней невозможны.
          </p>
          <p style={{ marginTop: "20px" }}>
            TOP GREEN не содержит в своем составе нитратов, хлоридов,
            пестицидов, гербицидов, ускорителей роста, фитогормонов и другой
            агрохимии. Даже 30 г TOP GREEN, добавленные в литр обычного грунта
            или даже бесплодного верхового торфа, делают почву в десятки раз
            плодороднее чернозема.
          </p>
          <p style={{ marginTop: "20px" }}>
            Таким образом, продукт идеально подходит для настоящего
            органического земледелия.
          </p>
        </>
      ),
    },
    {
      question: "Что такое TOP GREEN Протект?",
      answer:
        "TOP GREEN Протект — это продукт линейки Efektiv, который обеспечивает растения питанием на срок до одного года, укрепляет иммунную систему растений и повышает их устойчивость к грибковым заболеваниям (парша, ризоктониоз, фитофтороз и др.)",
    },
    {
      question: "TOP GREEN – это агрохимия?",
      answer:
        "Нет. TOP GREEN это питательная добавка длительного периода действия. Позволяет избежать необходимости постоянного контроля и корректировки состава в процессе роста растения. Экологичен. Не наносит вреда окружающей среде.",
    },
    {
      question: "Зачем нужен TOP GREEN?",
      answer:
        "Для выращивания здоровой и сильной рассады, экологически чистой зелени и овощей, адаптации и приживаемости растений после пересадки, для гармоничного роста и цветения цветов, а также для восстановления и увеличения плодородия деградированных или обеднённых почв и следовательно повышения урожайности.",
    },
    {
      question: "Как использовать TOP GREEN?",
      answer:
        "Внесите 5 мл TOP GREEN на 1 литр воды для грунта в прикорневую зону растения. Глубина внесения и количество грунта, которое необходимо извлечь, зависит от типа и размера растения.",
    },
    {
      question: "Чем может помочь TOP GREEN?",
      answer:
        "TOP GREEN дает растению сбалансированное питание на протяжении всего периода его жизни. Позволяет растению расти и плодоносить максимально эффективно.",
    },
    {
      question: "Для всех ли растений можно применять TOP GREEN?",
      answer:
        "Да, TOP GREEN можно использовать для любых типов растений. Для достижения максимального эффекта на отдельных типах растений мы разработали специализированные рецептуры.",
    },
  ];

  // Функция переключения раскрытия вопросов
  const toggleOpen = (index) => {
    setOpenIndexes(
      (prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index) // Убираем индекс, если он уже открыт
          : [...prev, index] // Добавляем индекс, если он закрыт
    );
  };

  // Состояние для отслеживания переворота каждой кнопки
  const [flippedState, setFlippedState] = useState({});

  // Обработчик клика на кнопку
  const handleButtonClick = (id) => {
    setFlippedState((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Переключаем состояние только для выбранной кнопки
    }));
  };
  // Открытие модального окна
  const openModal = () => setIsModalOpen(true);

  // Закрытие модального окна
  const closeModal = () => setIsModalOpen(false);

  // Закрытие модального окна при нажатии Escape
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]);

  const renderContent = () => {
    const scrollToElement = (selector) => (event) => {
      event.preventDefault();
      setTimeout(() => {
        document.querySelector(selector).scrollIntoView({ behavior: "smooth" });
      }, 250);
    };
    switch (activeContent) {
      case "catalog":
        return (
          <div className="main">
            <a
              className="catalog__main-arrow"
              href="#"
              onClick={scrollToElement("#main")}
            >
              <Arrow />
            </a>
            <div className="catalog__main-description">
              <p className="catalog__main-description-text-top">
                TOP GREEN — российский бренд,
                <br /> производящий высококачественные удобрения для всех видов
                растений.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса. Продукция TOP GREEN исключает риск передозировки, а
                также является полностью безопасной для использования.
              </p>
            </div>
            <div className="catalog__main-description-mobile">
              <p className="catalog__main-description-text-top">
                TOP GREEN — российский бренд,
                <br /> производящий высококачественные удобрения для всех видов
                растений.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса.
              </p>
              <p className="catalog__main-description-text-last">
                {" "}
                Продукция TOP GREEN исключает риск передозировки, а также
                является полностью безопасной для использования.
              </p>
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">удобрение универсальное</h2>
                <p className="catalog__description">
                  для декоративно-лиственных, цветущих
                  <br /> комнатных и садовых растений
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">
                    высококонцентрированный состав
                  </li>
                </ul>
                <div className="catalog__container">
                <Suspense fallback={<div>Загрузка...</div>}>
                  <Carousel
                    className="custom-carousel"
                    value={catalogDataUniversal} // Передаём данные
                    numVisible={2}
                    numScroll={1}
                    showNavigators={true} // Навигация
                    itemTemplate={productTemplate} // Используем шаблон карточек
                  />
                  </Suspense>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="1" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["1"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("1")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/272517646/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-universalnoe-dlya-rasteniy-800-ml-1757467182/?_bctx=CAQQr_mAAQ&at=BrtzNy4Lvu7Wp5B2S0W7ZO1f2JAy1GiWL3BW9iK2l581&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("1")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                  <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel 
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="info__container">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info info-universal">
                <h2 className="catalog__title catalog__title-mobile">
                  удобрение универсальное
                </h2>
                <img loading="lazy"
                  src={universal}
                  className="catalog__img catalog-universal"
                  alt="товар удобрение универсальное"
                />
              </div>
              <div className="info info-universal info-mobile">
                <h2 className="catalog__title catalog__title-mobile">
                  удобрение
                  <br /> универсальное
                </h2>
                <p className="catalog__description">
                  для декоративно-лиственных, цветущих
                  <br /> комнатных и садовых растений
                </p>
                <img loading="lazy"
                  src={universal}
                  className="catalog__img catalog-universal"
                  alt="товар удобрение универсальное"
                />
                <div className="info__container">
                  <MadeInRussia className="info__container-img" />
                  <div className="info__container-block">
                    <p className="info__container-text">
                      Заменяет все
                      <br /> удобрения
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title">удобрение универсальное</h2>
                <div className="info info-universal info-mobile">
                  <h2 className="catalog__title catalog__title-mobile">
                    удобрение
                    <br /> универсальное
                  </h2>
                  <p className="catalog__description">
                    для декоративно-лиственных, цветущих
                    <br /> комнатных и садовых растений
                  </p>
                  <img loading="lazy"
                    src={universal}
                    className="catalog__img catalog-universal"
                    alt="товар удобрение универсальное"
                  />
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">
                    высококонцентрированный состав
                  </li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="1" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["1"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("1")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/272517646/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-universalnoe-dlya-rasteniy-800-ml-1757467182/?_bctx=CAQQr_mAAQ&at=BrtzNy4Lvu7Wp5B2S0W7ZO1f2JAy1GiWL3BW9iK2l581&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("1")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      id="unique-id-1"
                      onClick={() => toggleVisibility("unique-id-1")}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        id="unique-id-1"
                        onClick={() => toggleVisibility("unique-id-1")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-1"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataUniversal}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">Для хвойных</h2>
                <p className="catalog__description">
                  для всех видов хвойных растений
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    насыщенный зелёный цвет круглый год
                  </li>
                  <li className="catalog__list-item">
                    на 99% снижает риск гибели
                  </li>
                  <li className="catalog__list-item">
                    быстрое восстановление после ожогов
                  </li>
                  <li className="catalog__list-item">
                    развитие корневой системы
                  </li>
                </ul>
                <div className="catalog__container">
                <Suspense fallback={<div>Загрузка...</div>}>
                  <Carousel
                    className="custom-carousel"
                    value={catalogDataHvoy}
                    numVisible={2}
                    numScroll={1}
                    showNavigators={true}
                    itemTemplate={productTemplate}
                  />
                  </Suspense>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="2" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["2"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("2")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a
                          href="https://www.wildberries.ru/catalog/244258164/detail.aspx?targetUrl=SN"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <WB className="catalog__flip_card_back-img" />
                        </a>

                        <a
                          href="https://www.ozon.ru/product/udobrenie-dlya-hvoynyh-top-green-udobrenie-osennee-dlya-sada-i-ogoroda-800ml-1628567853/?abt_att=2&origin_referer=ozon.ru&utm_campaign=productpage_link&utm_medium=share_button&utm_source=smm"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Ozon className="catalog__flip_card_back-img" />
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("2")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1.5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="info__container info__container-absolute info__container-absolute-hvoy">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info info-hvoy">
                <h2 className="catalog__title catalog__title-mobile">
                  Для хвойных
                </h2>
                <img loading="lazy" src={hvoy} className="catalog__img catalog-hvoy" alt="товар удобрение для хвойных" />
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title">Для хвойных</h2>
                <div className="info info-universal info-mobile">
                  <p className="catalog__description">
                    для всех видов хвойных растений
                  </p>
                  <img loading="lazy" src={hvoy} className="catalog__img catalog-hvoy" alt="товар удобрение для хвойных" />
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    насыщенный зелёный цвет круглый год
                  </li>
                  <li className="catalog__list-item">
                    на 99% снижает риск гибели
                  </li>
                  <li className="catalog__list-item">
                    быстрое восстановление после ожогов
                  </li>
                  <li className="catalog__list-item">
                    развитие корневой системы
                  </li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="2" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["2"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("2")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/244258164/detail.aspx?targetUrl=SN">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-hvoynyh-rasteniy-udobrenie-hvoynyh-derevev-800ml-1628567853/?__rr=1&from_sku=1628567853&oos_search=false">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("2")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-2")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-2"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataHvoy}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                     </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSizeSmall}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeSmallTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>

            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title" style={{ marginBottom: "40px" }}>
                  Для туи и
                  <br /> можжевельника
                </h2>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    восстанавливает после побурения
                  </li>
                  <li className="catalog__list-item">
                    стимулирует стрессоустойчивость
                  </li>
                  <li className="catalog__list-item">
                  насыщенный состав микроэлементов
                  </li>
                  <li className="catalog__list-item">
                    укрепляет иммунитет к заболеваниям
                  </li>
                </ul>
                <div className="catalog__container">
                <Suspense fallback={<div>Загрузка...</div>}>
                  <Carousel
                    className="custom-carousel"
                    value={catalogDataTui} // Передаём данные
                    numVisible={2}
                    numScroll={1}
                    showNavigators={true} // Навигация
                    itemTemplate={productTemplate} // Используем шаблон карточек
                  />
                  </Suspense>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="3" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["3"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("3")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/314518406/detail.aspx?targetUrl=SN">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-tui-i-mozhzhevelnika-top-green-udobrenie-hvoynoe-800ml-1836984023/?at=oZt6mQzykiO2BpoYTmxqyX9HRyOAXJfPvqDjosqy17gm&from_sku=1836984023&oos_search=false">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("3")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1.5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="info__container info__container-absolute info__container-absolute-tui">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <h2
                  className="catalog__title catalog__title-mobile"
                  style={{ marginBottom: "40px" }}
                >
                  Для туи и<br />
                  можжевельника
                </h2>
                <img loading="lazy" src={tui} className="catalog__img catalog__img-new" alt="товар удобрение для туи"/>
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title" style={{ marginBottom: "40px" }}>
                  Для туи и
                  <br /> можжевельника
                </h2>
                <div className="info info-universal info-mobile">
                  <img loading="lazy" src={tui} className="catalog__img catalog__img-new" alt="товар удобрение для туи"/>
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    восстанавливает после побурения
                  </li>
                  <li className="catalog__list-item">
                    стимулирует стрессоустойчивость
                  </li>
                  <li className="catalog__list-item">
                  насыщенный состав микроэлементов
                  </li>
                  <li className="catalog__list-item">
                    укрепляет иммунитет к заболеваниям
                  </li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="3" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["3"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("3")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/314518406/detail.aspx?targetUrl=SN">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-tui-i-mozhzhevelnika-top-green-udobrenie-hvoynoe-800ml-1836984023/?at=oZt6mQzykiO2BpoYTmxqyX9HRyOAXJfPvqDjosqy17gm&from_sku=1836984023&oos_search=false">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("3")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-3")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-3"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataTui}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSizeSmall}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeSmallTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title catalog__title-new">
                  Для рассады
                </h2>
                <p className="catalog__description">
                  для овощных, цветочных и ягодных культур
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    увеличивает размер и количество плодов
                  </li>
                  <li className="catalog__list-item">
                    повышает урожайность и созревание
                  </li>
                  <li className="catalog__list-item">
                    длительное плодоношение
                  </li>
                  <li className="catalog__list-item">
                    иммунитет к грибкам и заболеваниям
                  </li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Микроэлементы
                          </th>
                          <th className="catalog__container-item-title">
                            мг/л
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fe (ЭДТА)</td>
                          <td>123.9</td>
                        </tr>
                        <tr>
                          <td>Zn (ЭДТА)</td>
                          <td>29.8</td>
                        </tr>
                        <tr>
                          <td>Cu (ЭДТА)</td>
                          <td>23.4</td>
                        </tr>
                        <tr>
                          <td>Mn (ЭДТА)</td>
                          <td>39.8</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Состав(г/л):
                    </h3>
                    <p className="catalog__container-item-text">N - 41.2</p>
                    <p className="catalog__container-item-text">P205 - 39.7</p>
                    <p className="catalog__container-item-text">K20 - 40.5</p>
                    <p className="catalog__container-item-text">MgO - 3.99</p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="4" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["4"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("4")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/314248716/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-rassady-zhidkoe-udobrenie-dlya-ovoshchey-top-green-800-ml-1837077667/?_bctx=CAQQr_mAAQ&at=gpt4JD2z8tA2wwOVtLOnOXMUlPzXA3CDY0OktZO28QD&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("4")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1.5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="info__container info__container-absolute">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <h2 className="catalog__title catalog__title-mobile">
                  Для рассады
                </h2>
                <img loading="lazy"
                  src={rassada}
                  className="catalog__img catalog__img-new rassada"
                  alt="товар удобрение для рассады"
                />
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title">Для рассады</h2>
                <div className="info info-universal info-mobile">
                  <p className="catalog__description">
                    для овощных, цветочных и ягодных культур
                  </p>
                  <img loading="lazy"
                    src={rassada}
                    className="catalog__img catalog__img-new rassada"
                    alt="товар удобрение для рассады"

                  />
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    увеличивает размер и количество плодов
                  </li>
                  <li className="catalog__list-item">
                    повышает урожайность и созревание
                  </li>
                  <li className="catalog__list-item">
                    длительное плодоношение
                  </li>
                  <li className="catalog__list-item">
                    иммунитет к грибкам и заболеваниям
                  </li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="4" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["4"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("4")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/314248716/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-rassady-zhidkoe-udobrenie-dlya-ovoshchey-top-green-800-ml-1837077667/?_bctx=CAQQr_mAAQ&at=gpt4JD2z8tA2wwOVtLOnOXMUlPzXA3CDY0OktZO28QD&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("4")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-4")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-4"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataRassada}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSizeSmall}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeSmallTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2
                  className="catalog__title catalog__title-new"
                  style={{ marginBottom: "40px" }}
                >
                  Для томатов
                </h2>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    оптимальный баланс микроэлементов
                  </li>
                  <li className="catalog__list-item">долговременный эффект</li>
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Микроэлементы
                          </th>
                          <th className="catalog__container-item-title">
                            мг/л
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fe (ДТПА)</td>
                          <td>122.3</td>
                        </tr>
                        <tr>
                          <td>Zn (ЭДТА)</td>
                          <td>28.5</td>
                        </tr>
                        <tr>
                          <td>Cu (ЭДТА)</td>
                          <td>21.5</td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>88.9</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Состав(г/л):
                    </h3>
                    <p className="catalog__container-item-text">N - 24.4</p>
                    <p className="catalog__container-item-text">P205 - 16.1</p>
                    <p className="catalog__container-item-text">K20 - 61.58</p>
                    <p className="catalog__container-item-text">MgO - 2.7</p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="5" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["5"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("5")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/313686660/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-tomatov-udobrenie-zhidkoe-dlya-tomatov-800ml-1832647270/?_bctx=CAQQr_mAAQ&at=qQtJvV5M9cjwkw8Gt9A5PwXSlzoMQVFQ971LgTBy8WgM&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("5")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1.5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="info__container info__container-absolute">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <h2
                  className="catalog__title catalog__title-mobile"
                  style={{ marginBottom: "40px" }}
                >
                  Для томатов
                </h2>
                <img loading="lazy"
                  src={tomats}
                  className="catalog__img catalog__img-new tomats"
                  alt="товар удобрение для томатов"
                />
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title" style={{ marginBottom: "40px" }}>
                  Для томатов
                </h2>
                <div className="info info-universal info-mobile">
                  <img loading="lazy"
                    src={tomats}
                    className="catalog__img catalog__img-new tomats"
                    alt="товар удобрение для томатов"
                  />
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для повышения урожайности
                  </li>
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="5" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["5"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("5")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/313686660/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-tomatov-udobrenie-zhidkoe-dlya-tomatov-800ml-1832647270/?_bctx=CAQQr_mAAQ&at=qQtJvV5M9cjwkw8Gt9A5PwXSlzoMQVFQ971LgTBy8WgM&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("5")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-5")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-5"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataTomats}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSizeSmall}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeSmallTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2
                  className="catalog__title catalog__title-new catalog__title-klubnika"
                  style={{ marginBottom: "40px" }}
                >
                  Для клубники <br /> и ягодных
                </h2>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    адаптирует к условиям выращивания
                  </li>
                  <li className="catalog__list-item">
                    ускоряет созревание плодов
                  </li>
                  <li className="catalog__list-item">насыщенный вкус ягод</li>
                  <li className="catalog__list-item">
                    предотвращает заболевания
                  </li>
                </ul>
                <div className="catalog__container">
                  <div className="catalog__container-item">
                    <table className="border-none">
                      <thead>
                        <tr>
                          <th className="catalog__container-item-title">
                            Микроэлементы
                          </th>
                          <th className="catalog__container-item-title">
                            мг/л
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fe (ДТПА)</td>
                          <td>102</td>
                        </tr>
                        <tr>
                          <td>Zn (ЭДТА)</td>
                          <td>27.3</td>
                        </tr>
                        <tr>
                          <td>Cu (ЭДТА)</td>
                          <td>17.3</td>
                        </tr>
                        <tr>
                          <td>Mo</td>
                          <td>5.4</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="catalog__container-item">
                    <h3 className="catalog__container-item-title">
                      Состав(г/л):
                    </h3>
                    <p className="catalog__container-item-text">N - 33.44</p>
                    <p className="catalog__container-item-text">P205 - 9.32</p>
                    <p className="catalog__container-item-text">K20 - 47.41</p>
                    <p className="catalog__container-item-text">MgO - 2.9</p>
                  </div>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="6" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["6"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("6")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/314874433/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-klubniki-udobrenie-dlya-yagod-800-ml-1840121272/?_bctx=CAQQr_mAAQ&at=vQtrZR6kvfyjjG17tGkkvvyULxEYVou74YXyWFvmnM8Y&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("6")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <div className="catalog__buy_size-container">
                      <EightHundred />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">800</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          мл
                        </p>
                      </div>
                    </div>
                    <div className="catalog__buy_size-container">
                      <Liter />
                      <div className="catalog__buy_size-button">
                        <p className="catalog__buy_size-button_text-top">1.5</p>
                        <p className="catalog__buy_size-button_text-bottom">
                          л
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="info__container info__container-absolute info__container-absolute-klubnika">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <h2 className="catalog__title catalog__title-mobile">
                  Для клубники
                </h2>
                <img loading="lazy"
                  src={klubnika}
                  className="catalog__img catalog__img-new klubnika"
                  alt="товар удобрение для клубники"
                />
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title" style={{ marginBottom: "40px" }}>
                  Для клубники <br /> и ягодных
                </h2>
                <div className="info info-universal info-mobile">
                  <img loading="lazy"
                    src={klubnika}
                    className="catalog__img catalog__img-new klubnika"
                    alt="товар удобрение для клубники"
                  />
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    адаптирует к условиям выращивания
                  </li>
                  <li className="catalog__list-item">
                    ускоряет созревание плодов
                  </li>
                  <li className="catalog__list-item">насыщенный вкус ягод</li>
                  <li className="catalog__list-item">
                    предотвращает заболевания
                  </li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="6" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["6"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("6")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/314874433/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-klubniki-udobrenie-dlya-yagod-800-ml-1840121272/?_bctx=CAQQr_mAAQ&at=vQtrZR6kvfyjjG17tGkkvvyULxEYVou74YXyWFvmnM8Y&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("6")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-6")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
                {isVisible["unique-id-6"] && (
                  <div
                    className={`catalog__characteristic ${
                      isVisible ? "visible" : ""
                    }`}
                  >
                    <div className="catalog__container">
                      <h3>Состав</h3>
                      <Suspense fallback={<div>Загрузка...</div>}>
                      <Carousel
                        className="custom-carousel"
                        value={catalogDataKlubnika}
                        numVisible={1}
                        numScroll={1}
                        showNavigators={true}
                        itemTemplate={productTemplate}
                      />
                      </Suspense>
                    </div>
                    <div className="catalog__buy_size">
                      <h3>Фасовка</h3>
                      <Suspense fallback={<div>Загрузка...</div>}>
                      <Carousel
                        className="custom-carousel"
                        value={catalogDataSizeSmall}
                        numVisible={2}
                        numScroll={2}
                        showNavigators={true}
                        itemTemplate={productSizeSmallTemplate}
                      />
                      </Suspense>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="catalog__info">
              <img loading="lazy" src={RozeLeft} className="roze-left" alt="роза"/>
              <img loading="lazy" src={RozeRight} className="roze-right" alt="роза"/>
              <h2 className="catalog__info-title">
                Почему садоводы
                <br />
                выбирают top green?
              </h2>
              <h2 className="catalog__info-title-mobile">
                Почему
                <br />
                выбирают
                <br />
                top green?
              </h2>
              <div className="catalog__info_container_img">
                <Blueberry className="catalog__info_container_item-img" />
                <Garden className="catalog__info_container_item-img" />
                <Watercan className="catalog__info_container_item-img" />
                <Threes className="catalog__info_container_item-img" />
              </div>
              <div className="catalog__info_container">
                <div className="catalog__info_container_item">
                  <h3 className="catalog__info_container_item-title">
                    Максимальная
                    <br /> эффективность
                  </h3>
                  <p className="catalog__info_container_item-text">
                    Уникальная
                    <br /> формула Top Green
                    <br />
                    и натуральные
                    <br /> компоненты
                    <br /> роста.
                  </p>
                </div>
                <div className="catalog__info_container_item">
                  <h3 className="catalog__info_container_item-title">
                    Безопасность
                    <br /> в применении
                  </h3>
                  <p className="catalog__info_container_item-text">
                    Не содержит нитратов,
                    <br /> пестицидов, гербицидов,
                    <br />
                    ускорителей роста
                    <br /> и фитогармонов.
                  </p>
                </div>
                <div className="catalog__info_container_item">
                  <h3 className="catalog__info_container_item-title">
                    Простота в
                    <br /> использовании
                  </h3>
                  <p className="catalog__info_container_item-text">
                    Удобная схема смешивания
                    <br /> и внесения. <br />
                    Мерный колпачок в<br />
                    каждой таре
                  </p>
                </div>
                <div className="catalog__info_container_item">
                  <h3 className="catalog__info_container_item-title">
                    Биоразлагаемая
                    <br /> упаковка
                  </h3>
                  <p className="catalog__info_container_item-text">
                    ЭКО-материалы разлагаются
                    <br /> под воздействием природных <br /> факторов, что
                    уменьшает <br /> количество отходов,
                    <br /> накапливающихся
                    <br />
                    на свалках
                  </p>
                </div>
              </div>
              <div className="catalog__info_container-mobile">
              <Suspense fallback={<div>Загрузка...</div>}>
                <Carousel
                  className="custom-carousel"
                  value={catalogDataInfo} // Передаём данные
                  numVisible={1}
                  numScroll={1}
                  showNavigators={true} // Навигация
                  itemTemplate={infoTemplate} // Используем шаблон карточек
                />
                </Suspense>
              </div>
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title" style={{ marginBottom: "40px" }}>
                  Для пальм, фикусов, <br /> юкки и драцен
                </h2>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">защита от болезней</li>
                </ul>
                <div className="catalog__container">
                <Suspense fallback={<div>Загрузка...</div>}>
                  <Carousel
                    className="custom-carousel"
                    value={catalogDataPalm} // Передаём данные
                    numVisible={2}
                    numScroll={1}
                    showNavigators={true} // Навигация
                    itemTemplate={productTemplate} // Используем шаблон карточек
                  />
                  </Suspense>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="7" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["7"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("7")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/271754349/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-palm-fikusa-yukki-i-dratsen-100-ml-1738054070/?_bctx=CAQQr_mAAQ&at=Y7tjXwxy5iQ3rm3Yu4RWokDt6Zk7wjtrJXqjDSVq0XlD&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("7")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                  <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="info__container">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <h2 className="catalog__title catalog__title-mobile">
                  Для пальм, фикусов, <br /> юкки и драцен
                </h2>
                <img loading="lazy" src={palm} className="catalog__img catalog-palm" alt="удобрение для пальм"/>
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title" style={{ marginBottom: "40px" }}>
                  Для пальм, фикусов, <br /> юкки и драцен
                </h2>
                <div className="info info-universal info-mobile">
                  <img loading="lazy" src={palm} className="catalog__img catalog-palm" alt="удобрение для пальм"/>
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">защита от болезней</li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="7" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["7"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("7")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/271754349/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-palm-fikusa-yukki-i-dratsen-100-ml-1738054070/?_bctx=CAQQr_mAAQ&at=Y7tjXwxy5iQ3rm3Yu4RWokDt6Zk7wjtrJXqjDSVq0XlD&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("7")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-7")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-7"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataPalm}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">
                  Для всех видов
                  <br />
                  цветущих растений
                </h2>
                <p className="catalog__description">
                  для роз, бегоний, хризантем, гераней, лилий, <br />
                  пионов, гортензий, фиалок и др.
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">обильное цветение</li>
                </ul>
                <div className="catalog__container">
                <Suspense fallback={<div>Загрузка...</div>}>
                  <Carousel
                    className="custom-carousel"
                    value={catalogDataRoze} // Передаём данные
                    numVisible={2}
                    numScroll={1}
                    showNavigators={true} // Навигация
                    itemTemplate={productTemplate} // Используем шаблон карточек
                  />
                  </Suspense>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="8" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["8"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("8")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/271632716/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-roz-begoniy-i-hrizantem-1-5-l-1760868388/?_bctx=CAQQr_mAAQ&at=WPtN8VBn0CKAJOYrU1DjlXUV3jxKKCJ0vP98cJnNXj3&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("8")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                  </div>
                  <div className="info__container">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <h2 className="catalog__title catalog__title-mobile">
                  для роз, бегоний
                  <br />и хризантем
                </h2>
                <img loading="lazy" src={rose} className="catalog__img catalog-rose" alt="удобрение для роз"/>
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title">
                  Для всех видов
                  <br />
                  цветущих растений
                </h2>
                <div className="info info-universal info-mobile">
                  <p className="catalog__description">
                    для роз, бегоний, хризантем, гераней, лилий, <br />
                    пионов, гортензий, фиалок и др.
                  </p>
                  <img loading="lazy" src={rose} className="catalog__img catalog-rose" alt="удобрение для роз"/>
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">обильное цветение</li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="8" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["8"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("8")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/271632716/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-roz-begoniy-i-hrizantem-1-5-l-1760868388/?_bctx=CAQQr_mAAQ&at=WPtN8VBn0CKAJOYrU1DjlXUV3jxKKCJ0vP98cJnNXj3&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("8")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-8")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-8"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataRoze}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
            <div className="catalog__separator">
              <Separator className="catalog__separator-item" />
            </div>
            <div className="content">
              <div className="catalog">
                <h2 className="catalog__title">Для орхидных растений</h2>
                <p className="catalog__description">
                  для орхидей, фаленопсиса, цимбидиума,
                  <br /> онцидиума, каттлея и др.
                </p>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">обильное цветение</li>
                </ul>
                <div className="catalog__container">
                <Suspense fallback={<div>Загрузка...</div>}>
                  <Carousel
                    className="custom-carousel"
                    value={catalogDataOrc} // Передаём данные
                    numVisible={2}
                    numScroll={1}
                    showNavigators={true} // Навигация
                    itemTemplate={productTemplate} // Используем шаблон карточек
                  />
                  </Suspense>
                </div>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    <p className="catalog__buy_container_left-shop">
                      <strong>WB / Ozon / Все Инстр.</strong>
                    </p>

                    {/* Блок для кнопки с id="9" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["9"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("9")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/272623065/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-orhidey-800-ml-1763611082/?_bctx=CAQQr_mAAQ&at=qQtJvV5M9cwZ1J5AFGNYBPQH7VJKRQFEYrw2rcw9AmqD&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("9")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>

                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП для Юрлиц
                    </p>
                  </div>
                  <div className="catalog__buy_size">
                  <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="info__container">
                    <MadeInRussia className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <h2 className="catalog__title catalog__title-mobile">
                  Для орхидных растений
                </h2>
                <img loading="lazy" src={orc} className="catalog__img catalog-orc" alt="удобрение для орхидей"/>
              </div>
            </div>
            <div className="content-mobile">
              <div className="catalog">
                <h2 className="catalog__title">Для орхидных растений</h2>
                <div className="info info-universal info-mobile">
                  <p className="catalog__description">
                    для орхидей, фаленопсиса, цимбидиума,
                    <br /> онцидиума, каттлея и др.
                  </p>
                  <img loading="lazy" src={orc} className="catalog__img catalog-orc" alt="удобрение для орхидей"/>
                  <div className="info__container">
                    <MadeInRussiaMobile className="info__container-img" />
                    <div className="info__container-block">
                      <p className="info__container-text">
                        Заменяет все
                        <br /> удобрения
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="catalog__list">
                  <li className="catalog__list-item">
                    для корневой и листовой подкормки
                  </li>
                  <li className="catalog__list-item">всесезонное применение</li>
                  <li className="catalog__list-item">
                    исключён риск передозировки
                  </li>
                  <li className="catalog__list-item">обильное цветение</li>
                </ul>
                <div className="catalog__buy_container">
                  <div className="catalog__buy_container_left">
                    {/* Блок для кнопки с id="9" */}
                    <div
                      className={`catalog__flip_card ${
                        flippedState["9"] ? "flipped" : ""
                      }`}
                    >
                      {/* Передняя сторона кнопки */}
                      <div
                        onClick={() => handleButtonClick("9")}
                        className="catalog__flip_card_front"
                      >
                        <button className="catalog__buy_button">
                          <BuyButton />
                          Купить
                        </button>
                      </div>

                      {/* Задняя сторона кнопки */}
                      <div className="catalog__flip_card_back">
                        <a target="_blank" href="https://www.wildberries.ru/catalog/272623065/detail.aspx">
                        <WB className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="https://www.ozon.ru/product/udobrenie-dlya-orhidey-800-ml-1763611082/?_bctx=CAQQr_mAAQ&at=qQtJvV5M9cwZ1J5AFGNYBPQH7VJKRQFEYrw2rcw9AmqD&hs=1">
                        <Ozon className="catalog__flip_card_back-img" />
                        </a>
                        <a target="_blank" href="#">
                        <VseInstr className="catalog__flip_card_back-img" />
                        </a>
                        <Cancel
                          onClick={() => handleButtonClick("9")}
                          className="catalog__flip_card_back-cancel"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="catalog__buy_container_right">
                    <p
                      onClick={openModal}
                      className="catalog__buy_container_left-text"
                    >
                      Запросить КП
                    </p>
                    <p
                      onClick={toggleVisibility}
                      className="catalog__buy_container_left-text"
                    >
                      Характеристики
                    </p>
                  </div>
                  <div className="catalog__buy_container_right catalog__buy_container_right-mobile">
                    <ul>
                      <li
                        onClick={openModal}
                        className="catalog__buy_container_left-text"
                      >
                        Запросить КП
                      </li>

                      <li
                        onClick={() => toggleVisibility("unique-id-9")}
                        className="catalog__buy_container_left-text"
                      >
                        Характеристики
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {isVisible["unique-id-9"] && (
                <div
                  className={`catalog__characteristic ${
                    isVisible ? "visible" : ""
                  }`}
                >
                  <div className="catalog__container">
                    <h3>Состав</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataOrc}
                      numVisible={1}
                      numScroll={1}
                      showNavigators={true}
                      itemTemplate={productTemplate}
                    />
                    </Suspense>
                  </div>
                  <div className="catalog__buy_size">
                    <h3>Фасовка</h3>
                    <Suspense fallback={<div>Загрузка...</div>}>
                    <Carousel
                      className="custom-carousel"
                      value={catalogDataSize}
                      numVisible={2}
                      numScroll={2}
                      showNavigators={true}
                      itemTemplate={productSizeTemplate}
                    />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      case "legalEntities":
        return (
          <div>
            {" "}
            <div className="catalog__main-description">
              <p className="catalog__main-description-text-top">
                TOP GREEN — российский бренд,
                <br /> производящий высококачественные удобрения для всех видов
                растений.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса. Продукция TOP GREEN исключает риск передозировки, а
                также является полностью безопасной для использования.
              </p>
            </div>
            <div className="catalog__main-description-mobile">
              <p className="catalog__main-description-text-top">
                TOP GREEN — российский бренд,
                <br /> производящий высококачественные удобрения для всех видов
                растений.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса.
              </p>
              <p className="catalog__main-description-text-last">
                {" "}
                Продукция TOP GREEN исключает риск передозировки, а также
                является полностью безопасной для использования.
              </p>
            </div>
          </div>
        );
      case "faq":
        const toggleOpen = (index) => {
          setOpenIndexes((prevIndexes) => {
            // Если элемент уже открыт, его нужно скрыть с анимацией исчезновения
            if (prevIndexes.includes(index)) {
              return prevIndexes.filter((item) => item !== index);
            } else {
              // Если элемент закрыт, показываем его с анимацией
              return [...prevIndexes, index];
            }
          });
        };
        return (
          <div>
            {" "}
            <div>
              <div className="faq">
                <div className="faq__content">
                  <p className="faq__title">Вопросы и ответы</p>
                  <ul className="faq__list">
                    {faqData.map((item, index) => (
                      <li key={index} className="faq__item">
                        <div className="faq__item-container">
                          <p className="faq__item-text">{item.question}</p>
                          {/* Кнопка по умолчанию открытая (faq__item-button-open) */}
                          <button
                            className={
                              openIndexes.includes(index)
                                ? "faq__item-button-close"
                                : "faq__item-button-open"
                            }
                            onClick={() => toggleOpen(index)}
                          />
                        </div>
                        {/* Показываем текст ответа только для открытых элементов */}
                        <p
                          className={`faq__item-answer ${
                            openIndexes.includes(index) ? "open" : "closed"
                          }`}
                        >
                          {item.answer}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="catalog__main-description">
              <p className="catalog__main-description-text-top">
                TOP GREEN — российский бренд,
                <br /> производящий высококачественные удобрения для всех видов
                растений.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса. Продукция TOP GREEN исключает риск передозировки, а
                также является полностью безопасной для использования.
              </p>
            </div>
            <div className="catalog__main-description-mobile">
              <p className="catalog__main-description-text-top">
                TOP GREEN — российский бренд,
                <br /> производящий высококачественные удобрения для всех видов
                растений.
              </p>
              <p className="catalog__main-description-text-bottom">
                Наши удобрения способствуют росту и развитию растений, защищают
                от вредителей, обеспечивают быстрые сроки восстановления после
                стресса.
              </p>
              <p className="catalog__main-description-text-last">
                {" "}
                Продукция TOP GREEN исключает риск передозировки, а также
                является полностью безопасной для использования.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 425px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 425px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="content-switcher">
      <div className="content-switcher__buttons">
        <button
          className={`content-switcher__buttons-item ${
            activeContent === "catalog" ? "active" : ""
          }`}
          onClick={() => setActiveContent("catalog")}
        >
          Каталог
        </button>
        <button
          className={`content-switcher__buttons-item ${
            activeContent === "legalEntities" ? "active" : ""
          }`}
          onClick={() => setActiveContent("legalEntities")}
        >
          Для юрлиц
        </button>
        <button className="content-switcher__buttons-item" onClick={openModal}>
          Заявка
        </button>
        {isModalOpen && <Modal closeModal={closeModal} />}
        {/* <button
          className={`content-switcher__buttons-item ${
            activeContent === "faq" ? "active" : ""
          }`}
          onClick={() => setActiveContent("faq")}
        >
          {isMobile ? "F.A.Q." : "Вопросы и ответы"}
        </button> */}
      </div>
      <div className="card">{renderContent()}</div>
    </div>
  );
}

export default ContentSwitcher;
