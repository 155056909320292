import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import ContentSwitcher from "./components/ContentSwitcher";

const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Suspense fallback={<div style={{textAlign: 'center'}}>Загрузка...</div>}>
      <Header />
      <ContentSwitcher />
      <Footer />
    </Suspense>
  </React.StrictMode>
);


document.addEventListener("contextmenu", (e) => e.preventDefault());

document.addEventListener("keydown", (e) => {
  if (
    e.code === "F12" ||
    ((e.ctrlKey || e.metaKey) && e.code === "KeyU") ||
    ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === "KeyI") ||
    ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === "KeyC") ||
    ((e.ctrlKey || e.metaKey) && e.code === "KeyS")
  ) {
    e.preventDefault();
  }
});
